body {
    margin: 0;
    font-family: Montserrat,-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // background: radial-gradient(circle at top right,rgba(23,33,94,.9) 0,#f2b04f 25%);
    background-position: 100% 0;
    background-repeat: no-repeat;
}
.css-8apw0y {
    padding: 48px 16px;
}

.css-4znt71 {
    max-width: 500px;
    margin: 0px auto;
}

button{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Montserrat;
    line-height: 1.75;
    text-transform: uppercase;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fbf1e1;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1.2rem;
    padding: 10px;
    min-width: 138px;
    box-shadow: #00000096 6px 6px 20px 6px;
    color: rgb(23, 33, 94);
 
}
.topButtton{
    position: fixed;
    right: 48px;
    top: 48px;
}

.css-3fl4rv {
    margin: 20px 0px 0px;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    color: rgb(23, 33, 94);
}

.css-1qsl8ju{
    padding-bottom:24px;
}

.MuiBox-root{
    margin-bottom: 20px;
    text-align: center;
    position:relative;
}

h6{
    margin: -24px 0px 0px;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    color: rgb(23, 33, 94);
}

.MuiPaper-root{
    color: rgb(23, 33, 94);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    box-shadow: #00000096 6px 6px 20px 6px;
    border-radius: 20px;
    background: rgb(251, 241, 225);
    margin-bottom: 24px;
} 

.MuiCardContent-root{
    padding: 24px;
}

.MuiGrid-root{
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 24px;
}

.MuiTypography-body1{
    margin: 0px;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(23, 33, 94);
}

.MuiTypography-h5{
    margin: 0px;
    font-size: 24px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 1.334;
    color: rgb(23, 33, 94);
}

.css-ddmltu {
    padding-top: 32px;
    padding-bottom: 24px;
}

input[type='number']{
    font-size: 24px;
    font-weight: 500;
    padding: 8px 90px 8px 16px;
    text-align: right;
    border-radius: 0px;
    border: 1px solid rgb(85, 85, 85);
    background: white;
    width: 100%;
    outline: none;
}

.css-viowja {
    margin: 0px;
    font-family: Montserrat;
    line-height: 1.5;
    font-size: 24px;
    position: absolute;
    top: 9px;
    right: 18px;
    font-weight: 500;
    color: black;
}

button:disabled,
button[disabled]{
    pointer-events: none;
    cursor: default;
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12);
}

.css-m7b68o {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    margin-top:0px;
    width: 100%;
}

.css-5y52ga {
    box-sizing: border-box;
    margin: 24px 8px 0px 0px;
    flex-direction: row;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex: 1;
}

.css-xj09ev {
    box-sizing: border-box;
    margin: 24px 0px 0px 8px;
    flex-direction: row;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex: 1;
}

.css-5y52ga button, .css-xj09ev button{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Montserrat;
    line-height: 1.75;
    text-transform: uppercase;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgb(244, 181, 45);
    width: 100%;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1.2rem;
    padding: 10px;
    min-width: 100%;
    box-shadow: #00000096 6px 6px 20px 6px;
    color: rgb(23, 33, 94);
}

.css-4sd5su{
    width: 100%;
}

.css-rvkvz4 {
    margin: 0px;
    font-size: 24px;
    font-weight: 500;
    font-family: Montserrat;
    line-height: 1.334;
    color: rgb(23, 33, 94);
    border-bottom: 6px solid;
    padding-bottom: 8px;
}

.css-eyb7os {
    font-size: 10px;
    font-weight: 300;
    padding: 10px 12px;
    border-radius: 0px;
    border: 1px solid rgb(85, 85, 85);
    background: white;
    width: 100%;
    outline: none;
    color: rgb(23, 33, 94);
    margin:10px 0px;
}

.css-dir1dn {
    margin: 0px;
    font-size: 16px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 1.43;
    color: rgb(23, 33, 94);
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
}

.css-qwtwhb {
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
    width: calc(100% + 16px);
    margin-left: -16px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 32px;
}

.css-qwtwhb > .MuiGrid-item {
    padding-left: 16px;
    padding-top: 16px;
}
#root {
    height: 100%;
    overflow: auto;
}

.css-j1ayh8 {
    margin: 0px;
    font-size: 24px;
    font-family: Montserrat;
    line-height: 1.334;
    color: rgb(23, 33, 94);
    font-weight: bolder;
}
.css-vlu4wp {
    margin: 0px;
    font-size: 20px;
    font-family: Montserrat;
    line-height: 1.5;
    color: rgb(23, 33, 94);
    font-weight: bolder;
}