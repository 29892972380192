@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('./assets/font/Montserrat-Regular.ttf') format('ttf');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('./assets/font/Montserrat-Medium.ttf') format('ttf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('./assets/font/Montserrat-SemiBold.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('./assets/font/Montserrat-Bold.ttf') format('ttf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('./assets/font/Montserrat-ExtraBold.ttf') format('ttf');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

