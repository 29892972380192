@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@media(min-width: 992px){
    .container{
        max-width: 1100px!important;
    }
}

@media(min-width: 768px){
    .container{
        max-width: 1100px!important;
    }
}


@media (min-width:1280px){
    .container{
        max-width: 1240px!important;
    }
}

@media (min-width:1360px){
    .container{
        max-width: 1200px!important;
    }
}

@media(min-width: 576px){
    .container{
        max-width: 650px;
    }
}
